import React from "react";
import classNames from "classnames";
import { Container, Text } from "@atoms";
import loadable from "@loadable/component";

const Video = loadable(() => import("../atoms/Video"));

const CbVideo = ({
  caption,
  bgColor,
  noPadding,
  paddingBottom,
  paddingTop,
  videoUrl,
}) => {
  return (
    <section
      className={classNames({
        "bg-midnight": bgColor === "midnight",
        "bg-white": bgColor === "white",
      })}
    >
      <Container
        variant="xs"
        className={classNames({
          "my-8": noPadding,
          "pb-16 pt-16": !noPadding,
          "md:pt-28": paddingTop,
          "md:pb-28": paddingBottom,
        })}
      >
        {videoUrl && <Video url={videoUrl} />}
        {caption && (
          <Text variant="caption" className="mt-4 text-gray-dark">
            {caption}
          </Text>
        )}
      </Container>
    </section>
  );
};

CbVideo.defaultProps = {};

export default CbVideo;
