import React, { useState } from "react";
import classNames from "classnames";
import { m } from "framer-motion";

import { Text, Icon, Button } from "@atoms";

const Accordion = ({
  button,
  color,
  className: _className,
  copy,
  heading,
  order,
  startOpen,
}) => {
  const [itemOpen, setItemOpen] = useState(startOpen);

  const defaults = {
    type: "tween",
    duration: 0.3,
    ease: "easeInOut",
  };

  const variants = {
    open: {
      height: "auto",
      opacity: 1,
      transition: {
        ...defaults,
      },
    },
    close: {
      height: 0,
      opacity: 0,
      transition: {
        ...defaults,
      },
    },
  };

  return (
    <div
      className={classNames(
        "w-full border-b-2 border-black",
        {
          "border-t-2": order === 0,
        },
        _className
      )}
    >
      <button
        type="button"
        className={classNames("w-full duration-300", {
          "bg-green": itemOpen && color !== "green",
          "bg-black": itemOpen && color === "green",
        })}
        onClick={() => setItemOpen(!itemOpen)}
      >
        <div className="relative z-10 flex items-center justify-between gap-8 p-6 md:gap-12">
          <Text
            variant="h6"
            className={classNames("text-left", {
              "text-white duration-300": itemOpen && color === "green",
            })}
          >
            {heading}
          </Text>
          <Icon
            name="chevron"
            className={classNames(
              "h-4 w-4 flex-shrink-0 rotate-[90deg] transform duration-300",
              {
                "-rotate-[90deg]": itemOpen,
              }
            )}
          />
        </div>
      </button>
      <m.div
        initial={startOpen ? "open" : "close"}
        animate={itemOpen ? "open" : "close"}
        variants={variants}
        className={classNames(
          "w-full space-y-4 overflow-hidden px-4 text-left leading-normal transition-spacing duration-300",
          {
            "my-4": itemOpen,
            "my-0": !itemOpen,
          }
        )}
      >
        <Text richText>{copy}</Text>
        {button?.url && (
          <Button
            to={button.url}
            size="sm"
            download={button.download || button.type === "asset"}
          >
            {button.text}
          </Button>
        )}
      </m.div>
    </div>
  );
};

Accordion.defaultProps = {
  column: true,
  showBorders: true,
  startOpen: false,
};

export default Accordion;
