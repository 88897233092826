import resolveImage from "./resolveImage";

const resolvePageBuilder = (blocks, crumbs, current) =>
  blocks?.map((block, i) => {
    const prevBlock = blocks[i - 1];
    const nextBlock = blocks[i + 1];
    const { uid, type } = block;
    switch (type) {
      case "accordionList":
        return {
          uid,
          type,
          bgColor: block.bgColor,
          copy: block.copy,
          items: block.items,
          border:
            prevBlock?.type !== "ctaGrid" &&
            prevBlock?.type !== "utilityAnchorLink",
        };
      case "blogPreview":
        return {
          uid,
          type,
          showDivider: prevBlock?.type !== "hero",
          heading: block.heading,
          descriptor: block.descriptor,
          articles: block.articles?.map(article => {
            return {
              ...article,
              image: resolveImage(article.image),
            };
          }),
        };
      case "cardCarousel":
        return {
          uid,
          type,
          heading: block.heading,
          descriptor: block.descriptor,
          button: block.button,
          cards: block.cards?.map(card => {
            return {
              ...card,
              image: resolveImage(card.image),
            };
          }),
          paddingTop: prevBlock?.bgColor !== "white",
          showDivider: block.showDivider,
        };
      case "copy":
        return {
          uid,
          type,
          copy: block.copy,
          paddingTop: !prevBlock,
        };
      case "columnCopy":
        return {
          uid,
          type,
          heading: block.heading,
          descriptor: block.descriptor,
          copy: block.copy,
          buttons: block.buttons,
          showDivider:
            block.showDivider || prevBlock?.type === "sectionHeading",
          paddingTop: !prevBlock,
        };
      case "copyGrid":
        return {
          uid,
          type,
          bgColor: block.bgColor,
          heading: block.heading,
          items: block.items,
          paddingTop: prevBlock?.type === "sectionHeading",
        };
      case "cta":
        return {
          uid,
          type,
          bgColor: block.bgColor,
          heading: block.heading,
          descriptor: block.descriptor,
          button: block.button,
          download: block.download,
        };
      case "ctaGrid":
        return {
          uid,
          type,
          ctas: block.ctas?.map(cta => {
            return {
              ...cta,
              icon: resolveImage(cta.icon),
            };
          }),
          last: !nextBlock,
        };
      case "donationForm":
        return {
          uid,
          type,
          copy: block.copy,
          formId: block.formId,
        };
      case "directory":
        return {
          uid,
          type,
          collection: block.collection,
        };
      case "eventCarousel":
        return {
          uid,
          type,
          heading: block.heading,
          events: block.events?.map(event => {
            return {
              ...event,
              image: resolveImage(event.image),
            };
          }),
          paddingTop:
            prevBlock?.type === "hero" || prevBlock?.type === "infoGrid",
        };
      case "hero":
        return {
          uid,
          type,
          crumbs,
          current,
          heading: block.heading,
          image: resolveImage(block.image),
          caption: block.caption,
          button: block.button,
          links: block.links,
        };
      case "image":
        return {
          uid,
          type,
          image: resolveImage(block.image),
          paddingBottom: nextBlock?.type === "imageCopy" || !nextBlock,
        };
      case "imageCopy":
        return {
          uid,
          type,
          subheading: block.subheading,
          copy: block.copy,
          button: block.button,
          image: resolveImage(block.image),
          showDivider: true,
          order: i,
        };
      case "infoGrid":
        return {
          uid,
          type,
          items: block.items,
          paddingBottom: prevBlock?.type !== "hero",
        };
      case "jobList": {
        return {
          uid,
          type,
          showDivider: true,
          jobs: block.jobs,
        };
      }
      case "locationGrid":
        return {
          uid,
          type,
          locations: block.locations,
          paddingTop: prevBlock?.type === "hero" || !prevBlock,
          paddingBottom: true,
        };
      case "logoGrid":
        return {
          uid,
          type,
          copy: block.copy,
          groups: block.groups?.map(group => {
            const logos = group.logos?.map(logo => {
              return {
                ...logo,
                image: resolveImage(logo.image),
              };
            });
            return { ...group, logos };
          }),
        };
      case "openStudiosMap": {
        return {
          uid,
          type,
          allowFullScreen: block.allowFullScreen,
        };
      }
      case "peopleGrid":
        return {
          uid,
          type,
          heading: block.heading,
          initial: block.initial,
          people: block.people?.map(p => {
            return {
              ...p,
              image: !block.hideImages ? resolveImage(p.image) : null,
            };
          }),
          paddingTop: prevBlock?.type === "peopleGrid" || !prevBlock,
          paddingBottom: nextBlock?.type !== "peopleGrid" || !nextBlock,
        };
      case "schedule":
        return {
          uid,
          type,
          days: block.days,
        };
      case "sectionHeading":
        return {
          uid,
          type,
          showDivider: block.showDivider,
          bgColor: block.bgColor,
          heading: block.heading,
          descriptor: block.descriptor,
          button: block.button,
          paddingTop:
            prevBlock?.type !== "sectionHeading" && prevBlock?.type !== "copy",
          paddingBottom: nextBlock?.type !== "infoGrid",
        };
      case "testimonial":
        return {
          uid,
          type,
          showDivider: block.showDivider,
          quote: block.testimonial?.[0]?.quote,
          attribution: block.testimonial?.[0]?.attribution,
          position: block.testimonial?.[0]?.position,
          image: resolveImage(block.testimonial?.[0]?.image),
          overlap: prevBlock?.type === "cardCarousel",
        };
      case "utilityAnchorLink":
        return {
          uid,
          type,
          anchor: block.anchor,
        };
      default:
        return block;
    }
  });
export default resolvePageBuilder;
