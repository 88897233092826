import React, { useState, useRef, useEffect } from "react";
import { Image, Icon, Text, ButtonAlt } from "@atoms";
import { useScroll } from "framer-motion";

const SideBar = ({
  name,
  image,
  close,
  tags,
  mediums,
  bio,
  street,
  city,
  zip,
  lat,
  lon,
  artistNumber,
  website,
}) => {
  const outerProgress = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [headerTrigger, setHeaderTrigger] = useState(false);
  const { scrollYProgress } = useScroll({
    container: outerProgress,
  });

  const url = `https://www.google.com/maps/dir//${street.replace(
    /\s/g,
    "+"
  )},+${city.replace(/\s/g, "+")}+${zip}/@${lat},${lon},17z/`;

  useEffect(() => {
    const unsubscribe = scrollYProgress.onChange(v => {
      setHeaderTrigger(f => {
        if (v > 0.25 && !f) {
          return true;
        }
        if (f && v <= 0.25) {
          return false;
        }
        return f;
      });
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div
      className="fixed inset-0 flex flex-col overflow-auto overflow-x-hidden border-r-2 border-black bg-white text-black"
      ref={outerProgress}
    >
      <div className="relative flex h-40 w-full items-center justify-center border-b-2 border-black bg-gray sm:h-60">
        <div className="absolute inset-0 z-10">
          {image && <Image image={image} fill />}
        </div>
        <Icon
          className="relative z-0 h-1/2 text-white"
          fitHeight
          name="logoIcon"
        />
      </div>
      <div className="flex-grow divide-y-2 divide-white p-10">
        <div className="absolute left-5 top-5 z-20">
          <button type="button" onClick={close}>
            <Icon name="close" className="h-6 w-6 bg-gray" />
          </button>
        </div>

        <Text variant="h6" className="mb-4 border-b-2 border-black pb-2">
          <span>
            {name} #{artistNumber}
          </span>
        </Text>

        {bio && (
          <div className="mb-10 leading-tight">
            <Text variant="body">{bio}</Text>
          </div>
        )}
        {!!tags && (
          <Text variant="xs" className="font-bold">
            {tags.map(m => m.title).join(", ")}
          </Text>
        )}
        {!!mediums && (
          <Text variant="xs" className="font-bold">
            {mediums.map(m => m.title).join(", ")}
          </Text>
        )}
        {street && city && zip && (
          <address className="my-10 not-italic leading-normal">
            <p>{street}</p>
            <p>
              {city}, {zip}
            </p>
          </address>
        )}
        {website && (
          <div className="mb-10">
            <ButtonAlt to={website}>Website</ButtonAlt>
          </div>
        )}
        {url && (
          <div className="mb-10">
            <ButtonAlt to={url}>Get Directions</ButtonAlt>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideBar;
