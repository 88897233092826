import React from "react";
import useDefaultsData from "@staticQueries/DefaultsQuery";

import { buildCloudflareURL } from "@utils/imageUtils";

const Meta = ({
  title: _title,
  metaPageTitle: _metaTitle,
  metaDescription,
  metaFeaturedImage: _metaImage,
  url,
  slug,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { meta: data } = useDefaultsData();

  const { title: siteTitle, metaImage: defaultMetaImage } = data;

  if (typeof window === "undefined") {
    let errors = "";
    if (!_title) errors += "title ";
    if (!metaDescription) errors += "metaDescription ";
    // if (_metaImage) errors += "_metaImage ";
    if (!url) errors += "url";
    if (errors?.length) {
      // eslint-disable-next-line no-console
      console.log("\x1b[31m", `${slug} missing meta: ${errors}`);
    }
  }

  const metaTitle = _metaTitle || `${_title} → ${siteTitle}`;
  // const metaImage = metaImage

  const metaImage = _metaImage || defaultMetaImage || false;

  let metaImageUrl = "";

  if (metaImage?.url) {
    metaImageUrl = metaImage
      ? buildCloudflareURL({
          url: metaImage.url,
          fit: "scale-down",
          w: 1200,
        })
      : null;
  }

  return (
    <>
      <title>{metaTitle}</title>
      {/* <!-- Short description of the document (limit to 150 characters) --> */}
      {/* <!-- This content *may* be used as a part of search engine results. --> */}
      <meta name="description" content={metaDescription} />

      {/* <!-- Control the behavior of search engine crawling and indexing --> */}
      {/* <!-- All Search Engines --> */}
      <meta name="robots" content="index,follow" />
      {/* <!-- Google Specific --> */}
      <meta name="googlebot" content="index,follow" />

      {/* facebook og */}
      <meta property="og:title" name="og:title" content={metaTitle} />
      {metaImageUrl && metaImageUrl && metaImageUrl.length > 2 && (
        <meta property="og:image" name="og:image" content={metaImageUrl} />
      )}
      <meta
        property="og:description"
        name="og:description"
        content={metaDescription}
      />
      <meta property="og:type" name="og:type" content="website" />
      <meta
        property="og:url"
        name="og:url"
        content={`${url}${url?.slice(-1) !== "/" ? "" : "/"}`}
      />
      <meta
        property="fb:app_id"
        name="fb:app_id"
        content={process.env.GATSBY_FACEBOOK_APP_ID}
      />

      {/* twitter */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      {metaImageUrl && metaImageUrl && metaImageUrl.length > 2 && (
        <meta name="twitter:image" content={metaImageUrl} />
      )}
    </>
  );
};

Meta.defaultProps = {};

Meta.propTypes = {};

export default Meta;
