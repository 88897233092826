import React from "react";
import classNames from "classnames";
import { useAppState } from "@state";
import { Text, Image, Icon, ButtonAlt } from "@atoms";

const ModalContent = ({
  image,
  heading,
  position,
  content,
  tags,
  mediums,
  street,
  city,
  zip,
  lat,
  lon,
  website,
}) => {
  const [{ modal: modalState }, dispatch] = useAppState();
  const { modal: open } = modalState;

  const url =
    street && city && zip && lat && lon
      ? `https://www.google.com/maps/dir//${street.replace(
          /\s/g,
          "+"
        )},+${city.replace(/\s/g, "+")}+${zip}/@${lat},${lon},17z/`
      : "";

  return (
    <div
      className={classNames(
        "relative flex w-full flex-col gap-8 bg-white p-8 shadow-lg md:p-16",
        {
          "pointer-events-auto": open,
        }
      )}
    >
      <button
        type="button"
        className="absolute right-0 top-0 z-30 flex items-center p-4 text-black duration-300 hover:text-green"
        onClick={() => {
          dispatch({ type: "closeModal" });
        }}
        aria-label="Close modal"
      >
        <Icon name="close" className="h-6 w-6" />
      </button>
      <div className="flex flex-wrap items-center gap-8 sm:flex-nowrap">
        {image && (
          <div className="relative aspect-square w-28 sm:w-48">
            <Image
              image={image}
              className="overflow-hidden rounded-full"
              ixParams={{ fit: "facearea", facepad: 10 }}
              fill
            />
          </div>
        )}

        <div className="flex w-full flex-col gap-2 sm:w-auto">
          <Text variant="h5">{heading}</Text>
          <Text variant="lg">{position}</Text>
        </div>
      </div>
      <Text richText className="!max-w-none">
        {content}
      </Text>
      {!!tags && (
        <Text variant="xs" className="font-bold">
          {tags.map(m => m.title).join(", ")}
        </Text>
      )}
      {!!mediums && (
        <Text variant="xs" className="font-bold">
          {mediums.map(m => m.title).join(", ")}
        </Text>
      )}
      {street && city && zip && (
        <address className="not-italic leading-normal">
          <p>{street}</p>
          <p>
            {city}, {zip}
          </p>
        </address>
      )}
      {website && (
        <div>
          <ButtonAlt to={website}>Website</ButtonAlt>
        </div>
      )}
      {url && (
        <div>
          <ButtonAlt to={url}>Get Directions</ButtonAlt>
        </div>
      )}
    </div>
  );
};

ModalContent.defaultProps = {};

export default ModalContent;
