import React from "react";
import classNames from "classnames";
import { Text, Image, Icon } from "@atoms";
import { AppLink } from "@base";

const Wrapper = ({ condition, onClick, className: _className, children }) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {condition || onClick ? (
        <AppLink
          to={condition}
          onClick={onClick}
          className={classNames(_className)}
        >
          {children}
        </AppLink>
      ) : (
        <div className={classNames(_className)}>{children}</div>
      )}
    </>
  );
};

const CardSlide = ({ heading, descriptor, link, image }) => {
  return (
    <Wrapper
      condition={link?.url}
      className="group flex h-full flex-col justify-between"
    >
      <div className="flex flex-grow flex-col">
        <div
          className={classNames(
            "flex items-center justify-between gap-4 pb-4 md:items-start md:items-start md:pb-2",
            { "border-b-2 border-black": !descriptor }
          )}
        >
          <Text variant="h6">{heading}</Text>
          {link?.url && (
            <div className="mb-0 flex aspect-square items-center justify-center rounded-full bg-green p-2 duration-300 group-hover:translate-x-2 group-hover:bg-black md:mb-4">
              <Icon
                name="arrow"
                className="h-auto w-4 duration-300 group-hover:text-white"
              />
            </div>
          )}
        </div>
        {descriptor && (
          <Text variant="body" className="border-t-2 border-black pt-4">
            {descriptor}
          </Text>
        )}
      </div>
      <div className="relative mt-4 aspect-[3/2] md:mt-8 lg:aspect-square">
        <Image
          // ars={{ xs: [16, 9] }}
          sizes={{ xs: 1, lg: 0.5 }}
          image={image}
          fill
          draggable={false}
        />
      </div>
    </Wrapper>
  );
};

CardSlide.defaultProps = {};

export default CardSlide;
