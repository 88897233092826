// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";

const getPreviewData = async (query, variables, token) => {
  const url = `${process.env.GATSBY_CRAFT_API_URL}?token=${token}`;

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.GATSBY_CRAFT_API_TOKEN}`,
  };

  const body = JSON.stringify({
    query,
    variables,
  });

  const { data: previewData } = await fetch(url, {
    method: "post",
    headers,
    body,
  })
    .then(res => res.json())
    .then(res => {
      if (!res.errors) {
        return res;
      }
      throw Error(JSON.stringify(res.errors));
    })
    .catch(e => {
      // eslint-disable-next-line no-console
      console.log(e);
    });

  return previewData;
};

const useCraftPreview = ({ data: _data, query, variables }) => {
  const [data, setData] = useState({ data: _data, isPreview: false });
  // check if it's a preview and fetch preview data if so
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("token");
    const isPreview = !!token;
    const siteId = urlParams.get("site");

    if (isPreview) {
      // setData({ data: false, isPreview: true });
      const uri = variables.uri.replace(/^(os|wv)\//, "").replace(/^es\//, "");
      getPreviewData(
        query,
        {
          ...variables,
          uri: variables.uri === "" ? "home" : uri,
          siteId,
        },
        token
      )
        .then(previewData => {
          setData({ data: previewData, isPreview: true });
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log(e);
        });
    }
  }, []);

  return data;
};

export default useCraftPreview;
