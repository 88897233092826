import { useStaticQuery, graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";

const query = graphql`
  query OpenStudiosArtistsQuery {
    craft {
      entries(section: "people", type: ["openStudiosArtists"]) {
        ... on Craft_people_openStudiosArtists_Entry {
          slug
          name: title
          fName: firstName
          lName: lastName
          id
          uid
          type: typeHandle
          website
          url
          link: uri
          image: image0 {
            ...ImageFragment
          }
          bio: copy0
          tags: openStudiosArtistsTags {
            ... on Craft_openStudiosArtists_Category {
              uid
              title
            }
          }
          mediums: mediumTags {
            ... on Craft_mediums_Category {
              uid
              title
            }
          }
          street
          city
          zip
          lat: latitude
          lon: longitude
          artistNumber
        }
      }
    }
  }
`;

const dataResolver = ({ entries }) => {
  const artists = entries.map(entry => {
    return {
      ...entry,
      website: entry?.website?.length
        ? `http://${entry?.website?.replace(
            /^(https:\/\/|http:\/\/|\/\/)/,
            ""
          )}`
        : undefined,
      image: resolveImage(entry.image),
    };
  });

  return artists.reduce((a, b) => {
    // get group name
    const { type } = b;
    // spread existing group (if there is one)
    const group = [...(a[type] || []), b];
    // add the updated group to the object
    return { ...a, [type]: group };
  }, {});
};

const useData = () => {
  const { craft: data } = useStaticQuery(query);
  return dataResolver(data);
};

export default useData;
