import resolveImage from "./resolveImage";

const resolveContentBuilder = blocks => {
  const resolvedblocks = [];
  blocks.forEach(block => {
    const { type, uid } = block;
    switch (type) {
      case "blockQuote":
        resolvedblocks.push({
          type,
          uid,
          quote: block.quote,
          citation: block.citation,
        });
        break;
      case "buttonBlock":
        resolvedblocks.push({
          type,
          uid,
          url: block.link?.url,
          text: block.link?.text,
          style: block.style,
        });
        break;
      case "copy":
        resolvedblocks.push({
          type,
          uid,
          copy: block.copy,
        });
        break;
      case "cta":
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          descriptor: block.descriptor,
          link: block.link,
        });
        break;
      case "images":
        resolvedblocks.push({
          type,
          uid,
          images: block.images.map(resolveImage),
          caption: block.caption,
        });
        break;
      case "lead":
        resolvedblocks.push({
          type,
          uid,
          copy: block.copy,
        });
        break;
      case "logoGrid":
        resolvedblocks.push({
          type,
          uid,
          heading: block.heading,
          images: block.images,
        });
        break;
      case "video":
        resolvedblocks.push({
          type,
          uid,
          noPadding: true,
          videoUrl: block.videoUrl,
          caption: block.caption,
        });
        break;
      default:
        // do nothing
        break;
    }
  });

  return resolvedblocks;
};

export default resolveContentBuilder;
