import React from "react";
import classNames from "classnames";
import { Icon } from "@atoms";

const Logo = ({ scroll }) => {
  return (
    <Icon
      name="logo"
      fitHeight
      className={classNames("w-auto duration-300", {
        "h-10 text-white lg:h-12": scroll < 300,
        "h-6 text-transparent": scroll > 300,
      })}
    />
  );
};

export default Logo;
