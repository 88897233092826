// spanish translations
export default {
  "An Example": "Un ejemplo",
  "Back to Blog": "Volver al blog",
  "Connect With Us": "Conectar con nosotros",
  "Continue Reading": "Seguir leyendo",
  "Email Address": "Correo electrónico",
  "Enter a search term above to see results.":
    "Introduzca un término de búsqueda arriba para ver los resultados.",
  "First Name": "Nombre",
  "Get Our Newsletter & Stay Informed":
    "Recibe nuestro boletín & estar informado",
  "Last Name": "Apellido",
  "Learn More": "Aprende más",
  "Read More": "Lee más",
  "Search by Name": "Buscar por nombre",
  "Search the site...": "Buscar en el sitio...",
  "Sorry, your search returned no results.":
    "Lo sentimos, su búsqueda no ha dado ningún resultado.",
  "Thank you for subscribing": "Gracias por su suscripción.",
  "There are no items that match your selected filters.":
    "No hay artículos que coincidan con los filtros seleccionados.",
  "Try modifying your search terms and try again.":
    "Prueba a modificar los términos de búsqueda y vuelve a intentarlo.",
  "View Bio": "Ver bio",
  "Zip Code": "Código postal",
  Medium: "Medio",
  Share: "Compartir",
  Submit: "Enviar",
  by: "por",
  on: "el",
};
