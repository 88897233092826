import { buildCloudflareURL } from "@utils/imageUtils";

const imageResolver = _image => {
  const image = Array.isArray(_image) ? _image?.[0] : _image;
  if (image) {
    const { focalPoint, hasFocalPoint, url } = image;
    return {
      ...image,
      eagerUrl: buildCloudflareURL({ url, w: 360 }),
      focalPoint: hasFocalPoint ? focalPoint : false,
    };
  }
  return null;
};

export default imageResolver;
