import React from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { useAppState } from "@state";
import { AppLink } from "@base";
import MobileLink from "./MobileLink";

const MobileHeader = ({ nav, button, scroll }) => {
  const [{ layout }] = useAppState();

  // Mobile nav triggers
  const { hideNav } = layout;

  if (nav) {
    return (
      <nav
        aria-label="Mobile navigation"
        aria-live={!hideNav ? "polite" : "off"}
        className={classNames(
          "fixed inset-x-0 bottom-0 z-[100] transition-position duration-300",
          {
            "pointer-events-none": hideNav,
            "top-16": scroll < 300,
            "top-12": scroll > 300,
          }
        )}
      >
        <m.ul
          className={classNames(
            "relative z-50 flex h-full w-full flex-col items-start overflow-y-scroll lg:hidden",
            {
              "pointer-events-none": hideNav,
            }
          )}
          initial={{ opacity: 0 }}
          animate={!hideNav ? { opacity: 1 } : { opacity: 0 }}
        >
          {nav?.map((link, i) => {
            return <MobileLink key={link.uid} button={button} {...link} />;
          })}
          {button && (
            <li className="flex w-full items-start border-b-2 border-black bg-white p-4 shadow-md">
              <AppLink to={button.url}>
                <span className="block bg-green px-6 py-4 font-bold duration-300 hover:bg-black hover:text-white">
                  {button.text}
                </span>
              </AppLink>
            </li>
          )}
        </m.ul>
      </nav>
    );
  }
  return null;
};

MobileHeader.defaultProps = {};

export default MobileHeader;
