import React from "react";
import classNames from "classnames";
import { Text, AnimateIn } from "@atoms";
import { AppLink } from "@base";

const InfoGrid = ({ className: _className, paddingBottom, items }) => {
  return (
    <div className={classNames(_className)}>
      <div className="flex flex-wrap gap-4">
        {items?.map((item, i) => {
          const { heading, descriptor, text, url } = item;
          if (url || descriptor) {
            return (
              <AnimateIn
                preset="fadeUpFast"
                delay={0.2}
                className={classNames(
                  "flex flex-grow flex-col gap-2 border-l-2 border-black py-2 pl-4 sm:flex-initial sm:py-0"
                )}
              >
                {heading && <Text variant="h4">{heading}</Text>}
                {url?.length ? (
                  <AppLink to={url} icon={false}>
                    <Text className="underline decoration-green decoration-2 underline-offset-4 duration-300 hover:decoration-black">
                      {text}
                    </Text>
                  </AppLink>
                ) : (
                  <Text className="leading-tight">{descriptor}</Text>
                )}
              </AnimateIn>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

InfoGrid.defaultProps = {};

export default InfoGrid;
