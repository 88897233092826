import React from "react";
import classNames from "classnames";
import { Text, AnimateIn } from "@atoms";

const TagList = ({ className: _className, tags }) => {
  return (
    <AnimateIn
      preset="fadeUpFast"
      delay={0.4}
      threshold={0}
      className={classNames(_className)}
    >
      <div className="flex flex-wrap gap-x-4 gap-y-2 sm:gap-x-8 sm:gap-y-4">
        {tags.map(tag => {
          return (
            <Text key={tag.uid} variant="label">
              {tag.title}
            </Text>
          );
        })}
      </div>
    </AnimateIn>
  );
};

TagList.defaultProps = {};

export default TagList;
