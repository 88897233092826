import React from "react";
import classNames from "classnames";
import { m, AnimatePresence } from "framer-motion";
import { Text } from "@atoms";
import Pagination from "./Pagination";
import PersonCard from "./PersonCard";

const ArtistGrid = ({ className: _className, content }) => {
  const defaults = {
    type: "tween",
    ease: "easeInOut",
    duration: 0.3,
  };

  const variants = {
    enter: {
      y: 0,
      opacity: 1,
      transition: {
        ...defaults,
      },
    },
    exit: {
      y: 100,
      opacity: 0,
      transition: {
        ...defaults,
      },
    },
  };

  return (
    <div className={classNames(_className)}>
      <AnimatePresence mode="wait">
        {!!content?.length && (
          <Pagination
            component={PersonCard}
            data={content}
            pageSize={12}
            rangeLimit={2}
            showNavigation={false}
            variants={variants}
            className="grid w-full grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3"
          />
        )}
        {!content?.length && (
          <m.li
            key="no-results"
            initial="exit"
            animate="enter"
            exit="exit"
            variants={variants}
            className="col-span-1 flex flex-col items-center justify-center md:col-span-2 md:mt-20 lg:col-span-3"
          >
            <Text variant="xl">
              There are no items that match your selected filters.
            </Text>
          </m.li>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ArtistGrid;
