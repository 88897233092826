import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query OpenStudiosHeaderQuery {
    craft {
      en: globalSet(handle: "header", site: "openStudios") {
        ... on Craft_header_GlobalSet {
          linkMenus {
            ... on Craft_linkMenus_link_BlockType {
              uid
              headLink {
                url
                text
              }
              # linkGroups {
              #   ... on Craft_linkGroups_BlockType {
              #     uid
              #     group: sublinkGroup {
              #       ... on Craft_sublinkGroup_sublink_BlockType {
              #         uid
              #         type: typeHandle
              #         sublink: linkObject {
              #           text
              #           url
              #         }
              #       }
              #       ... on Craft_sublinkGroup_heading_BlockType {
              #         uid
              #         type: typeHandle
              #         heading
              #       }
              #     }
              #   }
              # }
            }
          }
          # button: link0 {
          #   url
          #   text
          # }
        }
      }
      es: globalSet(handle: "header", site: "openStudiosSpanishEs") {
        ... on Craft_header_GlobalSet {
          linkMenus {
            ... on Craft_linkMenus_link_BlockType {
              uid
              headLink {
                url
                text
              }
              # linkGroups {
              #   ... on Craft_linkGroups_BlockType {
              #     uid
              #     group: sublinkGroup {
              #       ... on Craft_sublinkGroup_sublink_BlockType {
              #         uid
              #         type: typeHandle
              #         sublink: linkObject {
              #           text
              #           url
              #         }
              #       }
              #       ... on Craft_sublinkGroup_heading_BlockType {
              #         uid
              #         type: typeHandle
              #         heading
              #       }
              #     }
              #   }
              # }
            }
          }
          # button: link0 {
          #   url
          #   text
          # }
        }
      }
    }
  }
`;

const dataResolver = globalSet => {
  const { linkMenus, button } = globalSet;
  const subnav = linkMenus?.map(links => {
    return {
      ...links?.headLink,
      uid: links?.uid,
      children: links?.linkGroups?.map(child => {
        return { ...child };
      }),
    };
  });
  return { subnav, button };
};

const useData = () => {
  const { craft } = useStaticQuery(query);
  const { en, es } = craft;
  return {
    en: dataResolver(en),
    es: dataResolver(es),
  };
};

export default useData;
