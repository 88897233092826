import React from "react";
import dayjs from "dayjs";
import t from "@utils/t";
import useLang from "@hooks/useLang";
import { Text, Image, Button } from "@atoms";

const EventSlide = ({ button, date, heading, descriptor, image }) => {
  const lang = useLang();
  return (
    <div className="relative flex h-full flex-col justify-between">
      <div className="grid grid-cols-1 gap-2 lg:grid-cols-3 lg:gap-6">
        <Text variant="h3" className="lg:col-span-2">
          {heading}
        </Text>
        {date && (
          <Text
            variant="label"
            className="self-end lg:col-start-3 lg:col-end-4 lg:justify-self-end"
          >
            {dayjs(date).format("MMMM D, YYYY - h:mm A")}
          </Text>
        )}
        <Text richText className="mt-4 prose-p:text-lg lg:col-span-3 lg:mt-0">
          {descriptor}
        </Text>
      </div>
      <div className="mt-6">
        <Image
          image={image}
          ars={{ xs: [2, 1] }}
          sizes={{ xs: 0.5, lg: 0.3 }}
          draggable={false}
        />
        {!!button?.url && (
          <div className="relative mt-6 md:absolute md:-bottom-16 md:right-0 md:mt-0">
            <Button to={button.url}>
              {button.text || t("Learn More", lang)}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

EventSlide.defaultProps = {};

export default EventSlide;
