import React from "react";
import {
  CbButton,
  CbCopy,
  CbCta,
  CbImages,
  CbLead,
  // CbLogoGrid,
  CbQuote,
  CbVideo,
} from "@molecules";

const ContentBuilder = ({ blocks }) => {
  if (blocks && blocks.length) {
    const cb = [...blocks];
    return cb?.map((block, i) => {
      const { type, uid } = block || {};
      switch (type) {
        case "blockQuote":
          return <CbQuote {...block} key={uid} />;
        case "buttonBlock":
          return <CbButton {...block} key={uid} />;
        case "copy":
          return <CbCopy {...block} key={uid} />;
        case "cta":
          return <CbCta {...block} key={uid} />;
        case "images":
          return <CbImages {...block} key={uid} />;
        case "lead":
          return <CbLead {...block} key={uid} />;
        case "video":
          return <CbVideo {...block} key={uid} />;
        default:
          return null;
      }
    });
  }
  return null;
};

ContentBuilder.defaultProps = {};

export default ContentBuilder;
