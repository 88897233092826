import React, { useMemo, useEffect, useState } from "react";
import useCraftPreview from "@hooks/useCraftPreview";
import useBrowserTheme from "@hooks/useBrowserTheme";
import { Script } from "gatsby";

import Meta from "./Meta";

export const HeadTemplate = React.memo(
  ({ data, loadMapStyles }) => {
    const { meta } = data;
    const theme = useBrowserTheme();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      if (process.env.GATSBY_USERBACK_TOKEN) {
        window.Userback = window.Userback || {};
        window.Userback.access_token = process.env.GATSBY_USERBACK_TOKEN;
        setLoaded(true);
      }
    }, []);

    return (
      <>
        {/* todo: load this conditionally AND during previews */}
        <link
          id="mapStyles"
          href="https://unpkg.com/maplibre-gl@3.2.1/dist/maplibre-gl.css"
          rel="stylesheet"
        />
        <link
          id="favicon"
          rel="icon"
          type="image/png"
          sizes="16x16 32x32"
          href={
            theme === "light" ? "/images/icon.png" : "/images/icon_darkmode.png"
          }
        />
        {loaded && (
          <Script
            id="userback"
            async
            src="https://static.userback.io/widget/v1.js"
          />
        )}
        <Meta {...meta} />
      </>
    );
  },
  () => true
);

const QueryContainer = ({
  data: _data,
  pageContext,
  location,
  template: Template,
  dataResolver,
  queryName,
  preview,
}) => {
  const pageQuery = JSON.parse(process.env.GATSBY_QUERIES)[queryName];
  // get preview data if a preview
  const { data, isPreview } = useCraftPreview({
    data: _data?.craft,
    query: pageQuery,
    variables: { ...(pageContext || {}), uri: location.pathname.slice(1) },
  });
  // memoize based on whether or it is previewData (and only process data once!)
  const resolvedData = useMemo(
    () => (data ? dataResolver(data) : false),
    [isPreview, typeof data]
  );
  // return the page component if there's data
  if (resolvedData) {
    return (
      <Template
        data={resolvedData}
        location={location}
        pageContext={pageContext}
      />
    );
  }
  // show preview loading when data is pending
  if (isPreview || preview) {
    return (
      <div className="fixed inset-0 z-[9999] flex h-full w-full flex-grow items-center justify-center bg-black text-2xl text-white">
        <div className="animate-pulse font-bold">Preview Loading...</div>
      </div>
    );
  }
  return (
    <div className="fixed inset-0 z-[9999] flex h-full w-full flex-grow flex-col items-center justify-center gap-3 bg-green text-white">
      <div className="text-2xl font-bold">Error.</div>
      <div className="text-sm">Something went wrong. Try again later.</div>
    </div>
  );
};

export default QueryContainer;
