import React, { useState } from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { Text, Button, Icon } from "@atoms";
import { AppLink } from "@base";

const Menu = ({ text, url, children, scroll }) => {
  const [visible, setVisible] = useState(false);
  const variants = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
  };

  return (
    <li
      className="relative hidden h-full items-center lg:flex"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <AppLink
        to={url}
        onClick={() => setVisible(false)}
        className="group flex items-center text-base font-bold text-white underline decoration-transparent decoration-2 underline-offset-4 duration-300 hover:decoration-green xl:text-sm"
        activeClassName="decoration-green"
      >
        {text}
        {!!children.length && (
          <Icon name="chevron" className="ml-2 h-3 w-3 rotate-90 text-white" />
        )}
      </AppLink>
      {!!children.length && (
        <m.div
          className={classNames(
            "absolute left-0 w-max bg-white shadow-lg transition-position duration-300",
            {
              "pointer-events-none": !visible,
              "top-18": scroll < 300,
              "top-12": scroll > 300,
            }
          )}
          animate={visible ? "open" : "closed"}
          initial="closed"
          variants={variants}
          transition={{ duration: 0.3 }}
        >
          <div className="border-b-2 border-black py-4 pl-12 pr-12">
            <Button to={url} onClick={() => setVisible(false)}>
              <Text className="text-base leading-tight xl:text-sm">{text}</Text>
            </Button>
          </div>

          {!!children.length && (
            <div
              className={classNames("py-8 pl-12 pr-12", {
                "columns-2 gap-8": children.length > 1,
              })}
            >
              {children.map(child => {
                return (
                  <ul
                    key={child.uid}
                    className="mt-8 break-inside-avoid space-y-4 first:mt-0"
                  >
                    {child.group?.map(item => {
                      const {
                        type: subtype,
                        uid: subuid,
                        heading: subheading,
                      } = item;
                      if (subtype === "sublink") {
                        const { text: subtext, url: suburl } = item.sublink;
                        return (
                          <li key={subuid}>
                            <AppLink
                              to={suburl}
                              onClick={() => setVisible(false)}
                              className="group text-base underline decoration-transparent decoration-2 underline-offset-4 duration-300 hover:decoration-green xl:text-sm"
                              activeClassName="decoration-green"
                            >
                              {subtext}
                            </AppLink>
                          </li>
                        );
                      }
                      return (
                        <li
                          key={subuid}
                          className="pt-4 text-base font-bold first:pt-0 xl:text-sm"
                        >
                          {subheading}
                        </li>
                      );
                    })}
                  </ul>
                );
              })}
            </div>
          )}
        </m.div>
      )}
    </li>
  );
};

Menu.defaultProps = {};

export default Menu;
