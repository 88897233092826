import React from "react";
import dayjs from "dayjs";
import t from "@utils/t";
import useLang from "@hooks/useLang";
import { Text, Icon } from "@atoms";
import { AppLink } from "@base";

const ArticleCard = ({ uri, title, author, date }) => {
  const lang = useLang();
  return (
    <AppLink to={uri} className="group">
      <div className="flex items-end gap-4">
        {/* article details */}
        <div className="flex flex-grow flex-col items-start space-y-2">
          <Text className="text-lg font-bold leading-tight">{title}</Text>
          <div className="flex flex-wrap gap-2 text-sm font-bold lg:gap-3">
            <div className="flex flex-grow space-x-1">
              <span className="text-gray-dark">{t("by", lang)}</span>
              <span>{author || "Arts Council SCC"}</span>
            </div>
            <div className="flex flex-grow space-x-1">
              <span className="text-gray-dark">{t("on", lang)}</span>
              <span>{dayjs(date).format("MMMM D, YYYY")}</span>
            </div>
          </div>
        </div>
        {/* icon */}
        <div className="flex aspect-square items-center justify-center rounded-full bg-green p-2 duration-300 group-hover:translate-x-2 group-hover:bg-black">
          <Icon
            name="arrow"
            className="h-auto w-4 duration-300 group-hover:text-white"
          />
        </div>
      </div>
    </AppLink>
  );
};

ArticleCard.defaultProps = {};

export default ArticleCard;
