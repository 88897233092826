import React from "react";
import classNames from "classnames";
import { Icon } from "@atoms";
import { AppLink } from "@base";

const Breadcrumbs = ({ className: _className, current, crumbs }) => {
  const classes =
    "flex-auto underline decoration-transparent decoration-2 underline-offset-4 duration-300 hover:decoration-green";

  return (
    <div
      className={classNames(
        "relative flex flex-wrap items-center gap-4",
        _className
      )}
    >
      <div className="flex items-center gap-4">
        <AppLink to={process.env.GATSBY_SITE_URL} className={classes}>
          Home
        </AppLink>
        <Icon name="chevron" className="h-3 w-3" />
      </div>
      {crumbs?.map(crumb => {
        const { uid, uri, title } = crumb;
        return (
          <div key={uid} className="flex items-center gap-4">
            <AppLink to={uri} className={classes}>
              {title}
            </AppLink>
            <Icon name="chevron" className="h-3 w-3" />
          </div>
        );
      })}
      <span className="font-bold">{current}</span>
    </div>
  );
};

Breadcrumbs.defaultProps = {};

export default Breadcrumbs;
