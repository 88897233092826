// spanish translations
export default {
  "An Example": "An Example",
  "Back to Blog": "Back to Blog",
  "Connect With Us": "Connect With Us",
  "Continue Reading": "Continue Reading",
  "Email Address": "Email Address",
  "Enter a search term above to see results.":
    "Enter a search term above to see results.",
  "First Name": "First Name",
  "Get Our Newsletter & Stay Informed": "Get Our Newsletter & Stay Informed",
  "Last Name": "Last Name",
  "Learn More": "Learn More",
  "Read More": "Read More",
  "Search by Name": "Search by Name",
  "Search the site...": "Search the site...",
  "Sorry, your search returned no results.":
    "Sorry, your search returned no results.",
  "Thank you for subscribing": "Thank you for subscribing",
  "There are no items that match your selected filters.":
    "There are no items that match your selected filters.",
  "Try modifying your search terms and try again.":
    "Try modifying your search terms and try again.",
  "View Bio": "View Bio",
  "Zip Code": "Zip Code",
  Medium: "Medium",
  Share: "Share",
  Submit: "Submit",
  by: "by",
  on: "on",
};
