import React, { useState } from "react";
import classNames from "classnames";
import t from "@utils/t";
import useLang from "@hooks/useLang";
import { Text, Icon } from "@atoms";
import { AppLink } from "@base";

const Social = ({ heading, social, color, className: _className }) => {
  const lang = useLang();
  const [active, setActive] = useState(null);
  if (social) {
    return (
      <div>
        <Text
          className={classNames("text-lg font-bold xl:text-base", {
            "text-white": color === "white",
            "text-black": color === "black",
          })}
        >
          {heading || t("Connect With Us", lang)}
        </Text>
        <div className="mt-4 flex flex-wrap gap-4">
          {social?.map((s, i) => {
            // handle multiple accounts
            if (s.type === "accounts") {
              return (
                <button
                  type="button"
                  key={s?.uid || `social-link--${i}`}
                  onClick={() =>
                    setActive(a => {
                      if (a !== i) return i;
                      return null;
                    })
                  }
                  onMouseEnter={() =>
                    setActive(a => {
                      if (a !== i) return i;
                      return a;
                    })
                  }
                  onMouseLeave={() =>
                    setActive(a => {
                      if (a === i) return null;
                      return a;
                    })
                  }
                  className={classNames(
                    "group relative flex h-8 w-8 items-center justify-center rounded-full p-2 duration-300 hover:bg-green",
                    {
                      "bg-white": color === "white",
                      "bg-black": color === "black",
                    },
                    _className
                  )}
                >
                  <Icon
                    name={s.platform}
                    className="flex h-full w-full items-center justify-center text-black"
                  />
                  <div
                    className={classNames(
                      "absolute bottom-0 flex flex-col items-start gap-2 border-2 border-black bg-white p-5 transition duration-300",
                      {
                        "-translate-y-6": active,
                        "pointer-events-none opacity-0": !active,
                      }
                    )}
                  >
                    {s.accounts.map(a => {
                      return (
                        <AppLink
                          key={a.label}
                          className="flex items-center gap-2 whitespace-nowrap text-sm font-bold text-black hover:text-green"
                          to={a.accountUrl}
                        >
                          <span>{a.label}</span>
                          <Icon name="arrow" className="mt-1 h-3 w-3" />
                        </AppLink>
                      );
                    })}
                  </div>
                </button>
              );
            }
            // handle single account
            return (
              <AppLink
                key={s?.uid || `social-link--${i}`}
                to={s?.accounturl}
                className={classNames(
                  "group h-8 w-8 rounded-full p-2 duration-300 hover:bg-green",
                  {
                    "bg-white": color === "white",
                    "bg-black": color === "black",
                  },
                  _className
                )}
              >
                <Icon
                  name={s.platform}
                  className="flex h-full w-full items-center justify-center text-black"
                />
              </AppLink>
            );
          })}
        </div>
      </div>
    );
  }
  return null;
};

Social.defaultProps = {
  color: "white",
};

export default Social;
