import React from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import useLang from "@hooks/useLang";
import t from "@utils/t";
import { Container, Text, Image, ButtonAlt } from "@atoms";

const ArticleHero = ({
  authors,
  className: _className,
  date,
  heading,
  image,
  topics,
}) => {
  const lang = useLang();

  const publishDate = dayjs(date).format("MMMM D, YYYY");

  return (
    <Container variant="xs">
      <ButtonAlt to="/community-stories" reverse>
        {t("Back to Blog", lang)}
      </ButtonAlt>
      <div className={classNames("mt-12 flex flex-col gap-8", _className)}>
        {image && (
          <Image
            image={image}
            ars={{ xs: [16, 9] }}
            sizes={{ xs: 1, lg: 0.5 }}
          />
        )}
        <Text className="text-4xl font-bold leading-tighter md:text-5xl">
          {heading}
        </Text>
        <div className="flex flex-col gap-4">
          {/* topics */}
          {!!topics.length && (
            <div className="flex flex-wrap gap-4 sm:gap-8">
              {topics.map(topic => {
                return (
                  <Text key={topic.uid} variant="label">
                    {topic.title}
                  </Text>
                );
              })}
            </div>
          )}
          {/* authors and date */}
          <div className="flex flex-wrap gap-4 xxs:gap-2">
            {!!authors.length && (
              <span className="flex gap-2 text-sm font-bold text-gray-dark">
                {t("by", lang)}
                <span className="text-black">
                  {authors.map(author => author.title).join(", ")}
                </span>
              </span>
            )}
            <span className="flex gap-2 text-sm font-bold text-gray-dark">
              {t("on", lang)}
              <span className="text-black">{publishDate}</span>
            </span>
          </div>
        </div>
      </div>
    </Container>
  );
};

ArticleHero.defaultProps = {};

export default ArticleHero;
