import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query CategoriesQuery {
    craft {
      en: categories {
        title
        uid
        groupHandle
      }
      es: categories(site: "artsCouncilSccSpanishEs") {
        title
        uid
        groupHandle
      }
    }
  }
`;

const dataResolver = categories => {
  return categories.reduce((a, b) => {
    // get group name
    const { groupHandle } = b;
    // spread existing group (if there is one)
    const group = [...(a[groupHandle] || []), b];
    // add the updated group to the object
    return { ...a, [groupHandle]: group };
  }, {});
};

const useCategoriesData = () => {
  const { craft } = useStaticQuery(query);
  const { en, es } = craft;
  return {
    en: dataResolver(en),
    es: dataResolver(es),
  };
};

export default useCategoriesData;
