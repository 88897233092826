import React, { useState } from "react";
import classNames from "classnames";
import t from "@utils/t";
import useLang from "@hooks/useLang";
import { Text, Input, Fieldset } from "@atoms";
import FormContainer from "./FormContainer";

const MailchimpForm = ({
  color,
  className: _className,
  descriptor,
  heading,
}) => {
  const lang = useLang();
  const [form, setForm] = useState({});

  const submitForm = async e => {
    try {
      e.preventDefault();

      if (!form.groups.length) {
        return {
          error: t("Please select at least one newsletter topic.", lang),
        };
      }

      const formData = { ...form, groups: form.groups.join(",") };

      const data = await fetch("/api/lgl", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      })
        .then(res => res.json())
        .catch(error => {
          return error;
        });

      return data;
    } catch (error) {
      return error;
    }
  };

  return (
    <div
      className={classNames("flex flex-wrap gap-8 lg:flex-nowrap", _className)}
    >
      {heading && (
        <Text variant="h4" className="text-white">
          {heading}
        </Text>
      )}
      <FormContainer
        onSubmit={submitForm}
        formState={[form, setForm]}
        successMessage={t("Thank you for subscribing.", lang)}
        color={color}
      >
        <Fieldset color={color} className="flex flex-wrap gap-x-4 gap-y-8">
          <Input
            key="first_name"
            id="first_name"
            name="first_name"
            label={t("First Name", lang)}
            placeholder={t("First Name", lang)}
            hideLabel
            required
            className="w-full lg:flex-1"
          />
          <Input
            key="last_name"
            id="last_name"
            name="last_name"
            label={t("Last Name", lang)}
            placeholder={t("Last Name", lang)}
            hideLabel
            required
            className="w-full lg:flex-1"
          />
          <Input
            key="email"
            id="email"
            name="email"
            label={t("Email Address", lang)}
            placeholder={t("Email Address", lang)}
            hideLabel
            required
            className="w-full"
          />
          <Input
            key="checkbox"
            type="checkbox"
            groupName="groups"
            group={[
              {
                id: "Arts Council Monthly Newsletter",
                name: "Arts Council Monthly Newsletter",
                value: "Arts Council Monthly Newsletter",
                label: "Arts Council Monthly Newsletter",
              },
              {
                id: "Tannery Monthly Newsletter",
                name: "Tannery Monthly Newsletter",
                value: "Tannery Monthly Newsletter",
                label: "Tannery Monthly Newsletter",
              },
              {
                id: "Arts Education Quarterly News",
                name: "Arts Education Quarterly News",
                value: "Arts Education Quarterly News",
                label: "Arts Education Quarterly News",
              },
              {
                id: "Watsonville Newsletter",
                name: "Watsonville Newsletter",
                value: "Watsonville Newsletter",
                label: "Watsonville Newsletter",
              },
              {
                id: "Visual Artists Opportunities",
                name: "Visual Artists Opportunities",
                value: "Visual Artists Opportunities",
                label: "Visual Artists Opportunities",
              },
              {
                id: "Grants (Becas)",
                name: "Grants (Becas)",
                value: "Grants (Becas)",
                label: "Grants (Becas)",
              },
            ]}
          />
        </Fieldset>
      </FormContainer>
    </div>
  );
};

MailchimpForm.defaultProps = {};

export default MailchimpForm;
