import React from "react";
import { ButtonAlt, Text } from "@atoms";
import { AppLink } from "@base";

const FooterNav = ({ nav }) => {
  const hasWindow = typeof window !== "undefined";

  const pathname = hasWindow && window.location?.pathname?.split("/");
  const slug = pathname ? `/${pathname[pathname.length - 1]}` : "";
  const isSamePage = url => hasWindow && slug === url;

  const scrollTo = target => {
    if (hasWindow) {
      document.getElementById(target)?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <div className="flex w-full flex-wrap gap-8">
      {nav.map((link, i) => {
        const { uid, url, text, button, children } = link;
        if (button && url) {
          return (
            <div key={uid} className="flex-[0_1_calc(50%-1rem)] lg:flex-auto">
              <ButtonAlt to={url}>{text}</ButtonAlt>
            </div>
          );
        }
        return (
          <ul
            key={uid || `${text}-${i}`}
            className="flex flex-[0_1_calc(50%-1rem)] flex-col gap-4 lg:flex-auto"
          >
            {url && (
              <li>
                <AppLink to={url} className="group">
                  <Text
                    variant="link"
                    className="font-bold group-hover:decoration-white"
                  >
                    {text}
                  </Text>
                </AppLink>
              </li>
            )}
            {!!children?.length && (
              <div>
                {children?.map(child => {
                  return (
                    <ul key={child.uid} className="space-y-2">
                      {child.group?.map(item => {
                        const {
                          type: subtype,
                          uid: subuid,
                          heading: subheading,
                        } = item;
                        if (subtype === "sublink") {
                          const { text: subtext, url: suburl } = item.sublink;
                          const baseUrl = suburl?.split("#")[0];
                          const anchor = suburl?.split("#")[1];
                          return (
                            <li key={subuid}>
                              <AppLink
                                to={
                                  isSamePage(baseUrl) && anchor ? null : suburl
                                }
                                onClick={() => {
                                  // scroll again when clicking the same anchor link on the same page
                                  if (isSamePage(baseUrl)) {
                                    scrollTo(anchor);
                                  }
                                }}
                                className="group"
                              >
                                <Text
                                  variant="linkSm"
                                  className="group-hover:decoration-white"
                                >
                                  {subtext}
                                </Text>
                              </AppLink>
                            </li>
                          );
                        }
                        return (
                          <li
                            key={subuid}
                            className="text-sm text-white xl:text-xs"
                          >
                            {subheading}
                          </li>
                        );
                      })}
                    </ul>
                  );
                })}
              </div>
            )}
          </ul>
        );
      })}
    </div>
  );
};

export default FooterNav;
