import React from "react";
import classNames from "classnames";
import useLang from "@hooks/useLang";
import t from "@utils/t";
import { Icon } from "@atoms";
import { AppLink } from "@base";

const Share = ({ title, url, className: _className }) => {
  const lang = useLang();
  const urls = {
    LinkedIn: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      url
    )}`,
    Twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`,
    Facebook: `https://www.facebook.com/dialog/share?app_id=${
      process.env.GATSBY_FACEBOOK_APP_ID
    }&display=page&href=${encodeURIComponent(
      url
    )}&redirect_uri=${encodeURIComponent(`https://www.facebook.com`)}`,
  };

  const classes =
    "group flex h-8 w-8 items-center justify-center rounded-full bg-green p-2 duration-300 hover:bg-black text-black hover:text-white";

  return (
    <div
      className={classNames("mt-16 flex items-center space-x-4", _className)}
    >
      <span className="text-lg font-bold">{title || t("Share", lang)}:</span>
      <AppLink to={urls.LinkedIn} target="_blank" className={classes}>
        <Icon name="linkedin" className="h-full w-full" fitHeight />
      </AppLink>
      {/* <AppLink to={urls.Twitter} target="_blank" className={classes}>
        <Icon name="twitter" className="h-full w-full" fitHeight />
      </AppLink> */}
      <AppLink to={urls.Facebook} target="_blank" className={classes}>
        <Icon name="facebook" className="h-full w-full" fitHeight />
      </AppLink>
    </div>
  );
};

Share.defaultProps = {};

export default Share;
