import React, { useState } from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { Text, Icon } from "@atoms";
import { AppLink } from "@base";

const MobileLink = ({ text, url, children, button }) => {
  const [showLinks, setShowLinks] = useState(false);

  const defaults = {
    duration: 0.3,
    type: "tween",
    ease: "easeInOut",
  };

  const listVariants = {
    open: {
      height: "auto",
      transition: {
        ...defaults,
      },
    },
    closed: {
      height: 0,
      transition: {
        ...defaults,
      },
    },
  };

  return (
    <li
      className={classNames("w-full", {
        "last:border-b-2 last:border-black last:shadow-md": !button,
      })}
    >
      <span
        className={classNames("flex items-center gap-2 p-4 duration-300", {
          "bg-white": !showLinks,
          "bg-green": showLinks,
        })}
      >
        <AppLink
          to={url}
          className="underline decoration-transparent decoration-2 underline-offset-4 duration-300 hover:decoration-green"
        >
          <Text variant="lg" className="font-bold">
            {text}
          </Text>
        </AppLink>
        {!!children?.length && (
          <button
            type="button"
            onClick={() => setShowLinks(!showLinks)}
            className="ml-auto"
          >
            <Icon
              name="chevron"
              className={classNames("h-4 w-4 rotate-90 duration-300", {
                "text-green": !showLinks,
                "-rotate-90 text-black": showLinks,
              })}
            />
          </button>
        )}
      </span>
      {children?.map(child => {
        return (
          <m.ul
            key={child.uid}
            initial="closed"
            animate={showLinks ? "open" : "closed"}
            variants={listVariants}
            className="overflow-hidden bg-white px-8"
          >
            {child.group?.map(item => {
              const { type: subtype, uid: subuid, heading: subheading } = item;
              if (subtype === "sublink") {
                const { text: subtext, url: suburl } = item.sublink;
                return (
                  <li className="py-4" key={subuid}>
                    <AppLink
                      to={suburl}
                      className="underline decoration-green/0 decoration-2 underline-offset-4 duration-300 hover:decoration-green"
                    >
                      <Text variant="sm">{subtext}</Text>
                    </AppLink>
                  </li>
                );
              }
              return (
                <li
                  key={subuid}
                  className="py-4 text-base font-bold uppercase xl:text-sm"
                >
                  {subheading}
                </li>
              );
            })}
          </m.ul>
        );
      })}
    </li>
  );
};

MobileLink.defaultProps = {};

export default MobileLink;
