// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import { useAppState } from "@state";

const useLang = () => {
  const [currentLang, setCurrentLang] = useState("en");
  const [{ translation }] = useAppState();
  const { lang } = translation;

  useEffect(() => {
    setCurrentLang(lang);
  }, [lang]);

  return currentLang;
};

export default useLang;
