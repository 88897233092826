import React from "react";
import classNames from "classnames";
import useLang from "@hooks/useLang";
import { Container } from "@atoms";
import { AppLink } from "@base";

import useOpenStudiosSubnav from "@staticQueries/OpenStudiosHeaderQuery";
import useWatsonvilleSubnav from "@staticQueries/WatsonvilleHeaderQuery";

const Subnav = ({ className: _className, site }) => {
  const lang = useLang();
  const osHeaderData = useOpenStudiosSubnav()[lang === "en" ? "en" : "es"];
  const wvHeaderData = useWatsonvilleSubnav()[lang === "en" ? "en" : "es"];
  const { subnav } = site === "openStudios" ? osHeaderData : wvHeaderData;

  return (
    <div className={classNames(_className)}>
      <Container>
        <div className="relative flex min-h-[1rem] flex-wrap gap-y-4 pt-12 md:pt-16 lg:-left-16">
          {subnav?.map((link, i) => {
            const { uid, text, url } = link;
            return (
              <span
                key={uid}
                className="border-l-2 border-black pl-4 pr-4 first:border-l-0 first:pl-0 last:pr-0"
              >
                <AppLink
                  to={url}
                  className="font-bold underline decoration-transparent decoration-2 underline-offset-4 duration-300 hover:decoration-green"
                  activeClassName="decoration-green"
                  partiallyActive={false}
                >
                  {text}
                </AppLink>
              </span>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

Subnav.defaultProps = {};

export default Subnav;
