import React from "react";
import { Container, Image, Text } from "@atoms";

const CbImage = ({ images, caption }) => {
  return (
    <Container variant="xs" className="my-8">
      {images.length === 1 && (
        <div className="relative">
          <Image image={images[0]} />
        </div>
      )}
      {images.length > 1 && (
        <div className="flex flex-wrap gap-8">
          {images?.map((image, i) => {
            return (
              <Image
                key={image.uid}
                // ars={{ xs: [16, 9] }}
                sizes={{ xs: 1, lg: 0.5 }}
                image={image}
              />
            );
          })}
        </div>
      )}
      {caption && (
        <Text className="mt-4 block text-sm text-gray-dark">{caption}</Text>
      )}
    </Container>
  );
};

CbImage.defaultProps = {};

export default CbImage;
