import React, { useRef, useEffect, useState } from "react";
import { m, useAnimation } from "framer-motion";
import classNames from "classnames";
import useWindowSize from "@hooks/useWindowSize";
import { Icon, AnimateIn } from "@atoms";

const Carousel = ({
  children,
  indicators,
  maxVisible,
  className: _className,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const [visibleSlides, setVisibleSlides] = useState(maxVisible);
  const slides = React.Children.map(children, (child, i) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        index: i,
      });
    }
    return child;
  });

  const slideCount = slides.length;
  const carouselControls = useAnimation();
  const { innerWidth: windowWidth } = useWindowSize();
  const carouselContainer = useRef();

  const screens = {
    xxs: { max: "350px" }, // for super small screens
    sm: "700px", // bigger than most phones
    md: "850px",
    lg: "1200px",
    xl: "1600px", // larger than 15" macbook pro
    xxl: "2000px",
  };

  const handleDrag = (event, info) => {
    const { x, y } = info.offset;
    if (Math.abs(x) > Math.abs(y)) {
      requestAnimationFrame(() => {
        if (x < -slideWidth / 2 / slideCount) {
          setCurrentSlide(prevState => {
            if (prevState < slides.length - visibleSlides) {
              return prevState + 1;
            }
            carouselControls.start({
              x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
            });
            return prevState;
          });
        } else if (x > slideWidth / 2 / slideCount) {
          setCurrentSlide(prevState => {
            if (prevState > 0) {
              return prevState - 1;
            }
            carouselControls.start({
              x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
            });
            return prevState;
          });
        } else {
          requestAnimationFrame(() => {
            carouselControls.start({
              x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
            });
          });
        }
      });
    } else {
      requestAnimationFrame(() => {
        carouselControls.start({
          x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
        });
      });
    }
  };

  // calculate # of slides that are visible
  const calculateVisibleSlides = width => {
    if (maxVisible > 1) {
      const screenNumbers = {};
      Object.keys(screens).map(screen => {
        if (typeof screens[screen] === "string") {
          screenNumbers[screen] = parseInt(
            screens[screen].replace("px", ""),
            10
          );
        }
        return true;
      });
      // configure number of slides based on screen size
      const noSlides = {
        xxs: 1,
        sm: 1,
        md: 1,
        lg: 2,
        xl: maxVisible,
        xxl: maxVisible,
      };
      // match screen
      const matchedScreen = Object.keys(screenNumbers).find(screen => {
        return width < screenNumbers[screen];
      });
      // return match
      if (matchedScreen && noSlides[matchedScreen] <= maxVisible) {
        return noSlides[matchedScreen];
      }
    }
    return maxVisible;
  };

  useEffect(() => {
    carouselControls.start({
      x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
    });
  }, [currentSlide]);

  // change slide width on window resize
  useEffect(() => {
    if (carouselContainer.current) {
      requestAnimationFrame(() => {
        setSlideWidth(carouselContainer.current.clientWidth);
      });
    }
  }, [carouselContainer, windowWidth]);

  // calculate visible slides on window resize
  useEffect(() => {
    if (carouselContainer.current) {
      requestAnimationFrame(() => {
        const newSlides = calculateVisibleSlides(windowWidth);
        setVisibleSlides(newSlides);
      });
    }
  }, [windowWidth]);

  return (
    <div ref={carouselContainer} className="relative w-full">
      <AnimateIn preset="fadeUpFast" delay={0.2}>
        <div className={classNames(_className)}>
          <m.div
            animate={carouselControls}
            className={classNames("flex")}
            transition={{ duration: 0.5, type: "tween" }}
            style={{ width: `${slideCount * 100}%` }}
            drag={slideCount > 1 ? "x" : false}
            onDragEnd={handleDrag}
            dragConstraints={{ left: "-100%", right: 0 }}
            dragDirectionLock
          >
            {slides.map((slide, i) => (
              <div
                key={slide.key}
                className={classNames("relative duration-300", {
                  "pointer-events-none opacity-20":
                    i < currentSlide || i + 1 > currentSlide + visibleSlides,
                })}
                style={{ width: `${(1 / visibleSlides / slideCount) * 100}%` }}
              >
                {slide}
              </div>
            ))}
          </m.div>
        </div>
        {slideCount > 1 && (
          <div className="mt-12 flex gap-4">
            {/* prev button */}
            <button
              className={classNames(
                "group flex items-center justify-center text-black transition duration-300",
                {
                  "opacity-20": currentSlide <= 0,
                }
              )}
              type="button"
              onClick={() => {
                setCurrentSlide(prevState => {
                  if (prevState > 0) {
                    return prevState - 1;
                  }
                  return prevState;
                });
              }}
              aria-label="Go to the previous slide"
            >
              <Icon
                name="chevron"
                className="relative right-0 h-6 w-6 rotate-180 duration-300 group-hover:-translate-x-2"
              />
            </button>
            {/* next button */}
            <button
              className={classNames(
                "group flex items-center justify-center text-black transition duration-300",
                {
                  "pointer-events-none opacity-20":
                    currentSlide >= slideCount - visibleSlides,
                }
              )}
              type="button"
              onClick={() => {
                setCurrentSlide(prevState => {
                  if (prevState < slideCount - visibleSlides) {
                    return prevState + 1;
                  }
                  return prevState;
                });
              }}
              aria-label="Go to the next slide"
            >
              <Icon
                name="chevron"
                className="relative right-0 h-6 w-6 duration-300 group-hover:translate-x-2"
              />
            </button>
            {/* indicators */}
            {indicators && (
              <ul className="flex flex-wrap items-center gap-1">
                {slides.map((slide, i) => (
                  <li key={slide.key}>
                    <div
                      // type="button"
                      // onClick={() => setCurrentSlide(i)}
                      className={classNames(
                        "block w-16 bg-black duration-300",
                        {
                          "h-1":
                            i === currentSlide ||
                            (i <= visibleSlides && visibleSlides > 1),
                          "h-0.5 opacity-50":
                            i < currentSlide ||
                            i + 1 > currentSlide + visibleSlides,
                        }
                      )}
                      // aria-label={`Go to slide ${i + 1}`}
                    >
                      <span className="hidden">{i}</span>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </AnimateIn>
    </div>
  );
};

Carousel.defaultProps = {
  maxVisible: 1,
};

export default Carousel;
