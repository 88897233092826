import React from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import useLang from "@hooks/useLang";
import t from "@utils/t";
import { Text, Image, Icon, Button } from "@atoms";
import { AppLink } from "@base";

const ListItem = ({
  authors,
  className: _className,
  date,
  descriptor,
  image,
  order,
  page,
  title,
  type,
  url,
}) => {
  // const typeTitle = type.replace(/([A-Z])/g, " $1");
  // const typeLabel = typeTitle.charAt(0).toUpperCase() + typeTitle.slice(1);
  const lang = useLang();

  const firstPage = order === 0 && page === 1;

  return (
    <AppLink
      to={url}
      className={classNames("group block", {
        "md:pb-16": firstPage,
      })}
    >
      <div
        className={classNames("flex flex-wrap items-start gap-4", _className)}
      >
        <div
          className={classNames("relative w-full flex-shrink-0", {
            "aspect-[2/1]": firstPage,
            "aspect-[4/3] sm:w-40": !firstPage,
            "bg-gray": !image,
          })}
        >
          {!image && (
            <div className="absolute inset-0 flex items-center justify-center">
              <Icon
                name="logoIcon"
                className="absolute w-1/5 max-w-[6rem] text-green md:w-1/3"
              />
            </div>
          )}
          {image && (
            <Image
              ars={{ xs: firstPage ? [2, 1] : [4, 3] }}
              sizes={{ xs: 1, lg: 0.5 }}
              image={image}
              fill
            />
          )}
        </div>
        <div className="flex flex-1 flex-col gap-4">
          <div className="flex flex-col gap-4">
            <Text
              className={classNames(
                "text-xl font-bold duration-300 group-hover:text-green ",
                {
                  "sm:text-2xl": firstPage,
                  "xl:text-lg": !firstPage,
                }
              )}
            >
              {title}
            </Text>
            <div className="flex flex-wrap gap-2 md:gap-4">
              {!!authors?.length && (
                <span className="flex-auto text-base font-bold text-gray-dark md:flex-initial xl:text-sm">
                  {t("by", lang)}
                  <span className="ml-1 text-base text-black xl:text-sm">
                    {authors?.map(author => author.title).join(", ")}
                  </span>
                </span>
              )}
              <span className="flex-auto text-base font-bold text-gray-dark md:flex-initial xl:text-sm">
                {t("on", lang)}
                <span className="ml-1 text-base text-black xl:text-sm">
                  {dayjs(date).format("MMMM D, YYYY")}
                </span>
              </span>
            </div>
          </div>
          <Text variant="body">{descriptor}</Text>
          <span>
            {firstPage && <Button>{t("Read More", lang)}</Button>}
            {!firstPage && (
              <AppLink className="text-base font-bold underline decoration-black decoration-2 underline-offset-4 duration-300 group-hover:decoration-green xl:text-sm">
                {t("Continue Reading", lang)}
              </AppLink>
            )}
          </span>
        </div>
      </div>
    </AppLink>
  );
};

ListItem.defaultProps = {};

export default ListItem;
